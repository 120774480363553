.zangu-container .title[data-v-b780935e] {
  text-align: center;
  font-size: 22px;
}
.zangu-container .header[data-v-b780935e] {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}
.zangu-container .bor_top[data-v-b780935e] {
  width: 15%;
  border-top: 1px solid #c2c2c2;
}
.zangu-container table[data-v-b780935e] {
  width: 100%;
  text-align: center;
}
.zangu-container table td[data-v-b780935e],
.zangu-container table th[data-v-b780935e] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
}
.zangu-container table th[data-v-b780935e]:nth-child(2), .zangu-container table td[data-v-b780935e]:nth-child(2) {
  border-left: 1px solid #c2c2c2;
}
.zangu-container table .tit[data-v-b780935e] {
  height: 25px;
  line-height: 25px;
  display: block;
}
.zangu-container table .money_unit[data-v-b780935e] {
  text-align: center;
  line-height: 22px;
  height: 22px;
  font-size: 14px;
  border-top: 1px solid #dadada;
  background-image: url("http://etax.qizhangfang.net/template/eaccount/line1.png");
  background-repeat: repeat-y;
  display: flex;
}
.zangu-container table .money_unit span[data-v-b780935e] {
  height: 100%;
  flex: 1;
  width: 18px;
}
.zangu-container table .td-money[data-v-b780935e] {
  width: 15%;
  text-align: center;
  background-image: url("http://etax.qizhangfang.net/template/eaccount/line1.png");
  background-repeat: repeat-y;
  width: 220px;
}
.zangu-container table .td-money input[data-v-b780935e] {
  border: 1px solid #f15a24;
  width: 100%;
  height: 45px;
}
.zangu-container table .td-money > div[data-v-b780935e] {
  width: 100%;
  text-align: right;
  letter-spacing: 10px;
  position: relative;
  right: -4px;
  height: 100%;
  line-height: 45px;
  font-size: 14px;
}
.zangu-container table .td-money > div span[data-v-b780935e] {
  height: 100%;
  flex: 1;
  width: 18px;
}
.zangu-container table tbody tr td[data-v-b780935e] {
  width: 150px;
  height: 45px;
}
.zangu-container table .col_operate[data-v-b780935e] {
  width: 26px;
  border: 1px solid #fff;
}
.zangu-container table .add[data-v-b780935e] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  font-size: 16px;
  color: #f15a24;
  cursor: pointer;
}
.zangu-container table .add .i[data-v-b780935e] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  margin-top: 10px;
}
.zangu-container table .close[data-v-b780935e] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  font-size: 18px;
  color: #F56C6C;
  cursor: pointer;
}